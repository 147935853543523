@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
}

html, body, #root {
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif;
  letter-spacing: 1px;
}

input[type="radio"]:checked + label span {
  background-color: #718096; /* bg-gray-600 */
  /* box-shadow: 0px 0px 0px 1px white inset; */
}

input[type="radio"]:checked + label{
  /* color: #3490DC; //text-blue */
}

.invalid-shadow:focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25)
}
.invalid-shadow-focus {
  box-shadow: 0 0 0 0.2rem rgba(220,53,69,.25)
}
.Toastify__toast.Toastify__toast--success,
.Toastify__toast.Toastify__toast--info,
.Toastify__toast.Toastify__toast--warn,
.Toastify__toast.Toastify__toast--error {
  border-radius: 5px;
}

input:checked + svg {
  display: block;
}
.animate__animated.animate__fadeIn, .animate__fadeOut{
	--animate-duration: 0.25s;
}

/* Chrome, Safari and Opera */
/* .no-scrollbar::-webkit-scrollbar {
  display: none;
} */



.scrollbar::-webkit-scrollbar {
  width: 10px;               /* width of the entire scrollbar */
}

.scrollbar::-webkit-scrollbar-track {
  background: rgb(245, 245, 245);        /* color of the tracking area */
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid rgb(245, 245, 245);   /* creates padding around scroll thumb */
}

/* .fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
} */
.image-message {
  max-width: 100%;
}

.pop-up {
  position: relative;
  z-index: 50;
}
.pop-up::after {
  display: none;
  position: absolute;
  top: 0;
  z-index: 10;
  left: 100%;
  width: 250px;
  text-align: center;
  padding: 5px;
  margin-left: 5px;
  border-radius: 0.75rem;
  color: gray;
  background-color: white;
  font-size: small;
  box-shadow: 0 0 10px 5px #00000033;
}
.pop-up:hover::after {
  display: block;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

#why, #how_works, #help {

  scroll-margin-block-start: 100px;
  /*Adds margin to the top of the viewport*/
}